<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
        <div class="iq-card-body">
          <div class="image-block">
            <img v-if="quizDetail && quizDetail.app_image" :src="quizDetail.app_image" class="img-fluid rounded w-100" alt="blog-img"/>
          </div>
          <div class="blog-description mt-2" >
            <h5 class="mb-3 pb-3 border-bottom">
              {{quizDetail && quizDetail.app_name ? quizDetail && quizDetail.app_name : ""}}
              <i class="fa fa-question-circle pointer mr-2 pull-right" @click="goToFaq()" style="font-size:25px;color: #e5252c;" title="FAQ"></i>
            </h5>
            <div class="row blog-meta d-flex mb-3">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <b-btn class="mr-3 mb-1" variant="primary" size="sm" @click="goToGpathAnalysis(true)" v-if="Object.keys(gpathSessionData).length == 0 || gpathSessionData.session_state == GPathData.GPATH_STATE_REPORT_GENERATED">
                  Let's Get Started
                </b-btn>
                <b-btn class="mr-3 mb-1" variant="primary" size="sm" @click="goToGpathAnalysis(false)" v-if="gpathSessionData && gpathSessionData.session_state < GPathData.GPATH_STATE_COMPLETED">
                  Continue Assessment
                </b-btn>
                <b-btn class="mr-3 mb-1" variant="primary" size="sm" @click="goToGpathSessionDashboard()" v-if="reportCount > 1">
                  Previous Reports
                </b-btn>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3 mb-1" variant="primary" size="sm" @click="gPathDashboard()">
                  GPaths Dashboard
                </b-btn>
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3" variant="primary" size="sm" @click="gPathEdit()">
                  Edit
                </b-btn>
                <b-btn class=" mr-3 mb-1 pull-right" variant="primary" size="sm" @click="goToGpathReportGenerate()" v-if="gpathSessionData && (gpathSessionData.session_state == 8 || gpathSessionData.session_state == 9) && !gpathSessionData.report">
                  Generate Report
                </b-btn>
                <b-btn class=" mr-3 mb-1 pull-right" variant="primary" size="sm" @click="openReport(gpathSessionData.report)" v-if="gpathSessionData && gpathSessionData.report">
                  Download Report
                </b-btn>
              </div>
            </div>
            <p v-if="quizDetail && quizDetail.app_description" class="vhtmlTag vueHtmlParent mt-3" v-html="quizDetail.app_description">
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Upcomming events -->
    <div class="col-lg-4 gide_right_mobile_data">
      <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)"/>
      <CareerHomeList v-if="!isMobileDevice"/>
    </div>

    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <AlertBox :key="showNewSessionAlertBox" v-if="showNewSessionAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText"
    :propCancelBtnText="cancelText" @emitCloseAlertModel="showNewSessionAlertBox = false" @emitConfirmAlertModel="addGpathSession"/>
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import AlertBox from "../../../components/AlertBox.vue"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import GPathData from "../../../FackApi/json/GPath.json"
import { GPathSessions } from "../../../FackApi/api/GPathSession"
import { Apps } from "../../../FackApi/api/apps.js"
import cacheLs from "../../../store/cacheLs.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"

export default {
  name: "GPathProductPage",
  components: {
    AlertBox,
    EventHomeList,
    CareerHomeList
  },
  data () {
    return {
      apiName: "gpath_session_view",
      cvTitle: "GPaths",
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      toastVariant: "",
      showToast: false,
      toastMsg: "",
      toastTitle: "GPaths",
      showNewSessionAlertBox: false,
      alertDesc: "You have already completed the GPaths: Career Assessment. If you choose to retake the assessment again then you will retain your previous report, however, your previous assessment data will not be valid anymore. Click on Continue Button to proceed taking the assessment.",
      alertTitle: "Warning",
      okText: "Continue Assessment",
      cancelText: "Cancel",
      source: "ONLINE",
      GPathData: GPathData,
      session_state: null,
      quizDetail: null,
      app_id: GPathData.GPATH_WORKFLOW_ID,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      reportCount: 0
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    gpathSessionData () {
      return this.$store.getters["GpathSession/gpathSession"]
    }
  },
  mounted () {
    socialvue.index()
    this.appView()
    this.viewGpathSession()
  },
  methods: {
    /**
     * Redirect to FAQ
     */
    goToFaq () {
      this.$router.push(`./faq_list/gpaths`)
    },
    /**
     * gPathEdit
     */
    gPathEdit () {
      window.open(`quiz_edit/${this.app_id}`, "_blank")
    },
    /**
     * openReport
     */
    openReport (url) {
      window.open(url, "_blank")
    },
    /**
     * goToGpathAnalysis
     */
    async goToGpathAnalysis (addSession) {
      if (this.gpathSessionData && this.gpathSessionData.session_state == this.GPathData.GPATH_STATE_REPORT_GENERATED) {
        this.showNewSessionAlertBox = true
      }
      else if (this.gpathSessionData && this.gpathSessionData.session_state == this.GPathData.GPATH_STATE_COMPLETED) {
        this.$router.push("/gpath_report_generate")
      }
      else {
        if (addSession) {
          this.addGpathSession()
        }
        else {
          this.$router.push("/gpath_home")
        }
      }
    },
    /**
     * goToGpathSessionDashboard
     */
    goToGpathSessionDashboard () {
      this.$router.push("/gpath_session_list")
    },
    /**
     * gPathDashboard
     */
    async gPathDashboard () {
      this.$router.push("/gpath_session_list")
    },
    /**
     * goToGpathReportGenerate
     */
    goToGpathReportGenerate () {
      this.$router.push("/gpath_report_generate")
    },
    /**
     * viewGpathSession
     */
    async viewGpathSession () {
      try {
        // See if the session for the user is created or not
        let payload = {
          user_id: this.userData.user_id,
          latest_incomplete_session: true
        }
        let gpathSessionViewResp = await GPathSessions.gpath_sessionView(this, null, payload)

        if ((gpathSessionViewResp && !gpathSessionViewResp.resp_status)) {
          // clearing user's session in local storage
          cacheLs.delete_gpath_session()
          this.$store.dispatch("GpathSession/setGpathSession", {})
          this.reportCount = gpathSessionViewResp.report_count
          return
        }

        // storing the count of sessions to decide whether to show dashboard or not
        gpathSessionViewResp.resp_data.data.report_count = gpathSessionViewResp.report_count ? gpathSessionViewResp.report_count : 0
        this.reportCount = gpathSessionViewResp.resp_data.data.report_count

        // storing the sessions details in local storage
        this.$store.dispatch("GpathSession/setGpathSession", gpathSessionViewResp.resp_data.data)
      }
      catch (error) {
        console.error("Exception in viewGpathSession() ", error.message)
      }
    },
    /**
     * addGpathSession
     */
    async addGpathSession () {
      let payload = {}
      payload.session_state = GPathData.GPATH_STATE_INITIATED
      payload.session_state_desc = GPathData.GPATH_STATE_INITIATED_MSG

      let gpathSessionAddResp = await GPathSessions.gpath_sessionAdd(this, payload)

      if (gpathSessionAddResp && gpathSessionAddResp.resp_status) {
        // Add the session data into store
        this.$store.dispatch("GpathSession/setGpathSession", gpathSessionAddResp.resp_data)
        this.$router.push(`gpath_home`)
      }
    },
    /**
     * Get Info
     */
    async appView () {
      try {
        this.quizId = this.app_id
        const quizResp = await Apps.appView(this, this.quizId)
        this.quizDetail = quizResp.resp_data.data
      }
      catch (error) {
        console.error("Exception in appview()", error.message)
      }
    }
  }
}
</script>
<style>
@media (min-width: 576px){
  .right_button{
    float: right;
  }
}
</style>
